import store from '@/state/store';

export default [
  {
    path: '/p/:code',
    name: 'affiliate',
    meta: {
      authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        location.href = "https://register.huggy.io?partner_code="+routeTo.params.code
      },
    },
  },
  {
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/home'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/account/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: () => import('./views/account/forgot-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: '/reset-password',
    name: 'Reset password',
    component: () => import('./views/account/reset-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('auth/logOut');
        // const authRequiredOnPreviousRoute = routeFrom.matched.some(
        //   (route) => route.push('/login')
        // );
        // Navigate back to previous page, or home as a fallback
        next({ name: 'home' });
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: import('./views/utility/404').default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // import some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
  {
    path: '/companies',
    name: 'Companies',
    meta: { authRequired: true },
    component: () => import('./views/companies/companies'),
  },
  {
    path: '/companies/:id',
    name: 'CompanyDetails',
    meta: { authRequired: true },
    component: () => import('./views/companies/companies-details'),
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    meta: { authRequired: true },
    component: () => import('./views/subscriptions/subscriptions'),
  },
  {
    path: '/subscriptions/cancellations',
    name: 'SubscriptionCancellations',
    meta: { authRequired: true },
    component: () => import('./views/subscriptions/subscription-cancellations'),
  },
  {
    path: '/subscriptions/due',
    name: 'DueSubscriptions',
    meta: { authRequired: true },
    component: () => import('./views/subscriptions/subscriptions-due'),

  },
  {
    path: '/subscriptions/:id',
    name: 'SubscriptionDetails',
    meta: { authRequired: true },
    component: () => import('./views/subscriptions/subscriptions-details'),
  },
  {
    path: '/orders',
    name: 'Orders',
    meta: { authRequired: true },
    component: () => import('./views/orders/orders'),
  },
  {
    path: '/orders/:id',
    name: 'OrderDetails',
    meta: { authRequired: true },
    component: () => import('./views/orders/orders-details'),
  },
  {
    path: '/payments',
    name: 'Payments',
    meta: { authRequired: true },
    component: () => import('./views/payments/payments'),
  },
  {
    path: '/payments/failed',
    name: 'OrderFailedPayments',
    meta: { authRequired: true },
    component: () => import('./views/payments/payments-failed'),
  },
  {
    path: '/funds',
    name: 'Funds',
    meta: { authRequired: true },
    component: () => import('./views/funds/funds'),
  },
  {
    path: '/sales',
    name: 'Sales',
    meta: { authRequired: true },
    component: () => import('./views/sales/sales'),
  },
  {
    path: '/bank-accounts',
    name: 'BankAccounts',
    meta: { authRequired: true },
    component: () => import('./views/bank-accounts/bank-accounts'),
  },
  {
    path: '/withdrawals',
    name: 'Withdrawals',
    meta: { authRequired: true },
    component: () => import('./views/withdrawals/withdrawals'),
  },
  {
    path: '/notifications',
    name: 'Notifications',
    meta: { authRequired: true },
    component: () => import('./views/notifications/notifications'),
  },
  {
    path: '/notifications/:id',
    name: 'NotificationDetails',
    meta: { authRequired: true },
    component: () => import('./views/notifications/notifications-details'),
  },
  {
    path: '/users',
    name: 'Users',
    meta: { authRequired: true },
    component: () => import('./views/users/users'),
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { authRequired: true },
    component: () => import('./views/users/profile'),
  },
  {
    path: '/partner',
    name: 'Partner',
    meta: { authRequired: true },
    component: () => import('./views/partners/partner'),
  }
];
