/**
 *
 * User
 * @class User
 */
export class User {

  /**
     * Creates an instance of User.
     * @param { UserObj } userObj
     * @memberof User
     */

  constructor(userObj) {
    if(typeof userObj === 'undefined' || userObj === null){
      userObj = {};
    }

    this.id         = userObj.id || null;
    this.partner_id = userObj.partner_id || null;
    this.name       = userObj.name || null;
    this.email      = userObj.email || null;

    this.created_at = new Date();

    if(typeof userObj.created_at !== "undefined"){
      this.created_at = new Date(userObj.created_at);
    }
  }

  /**
     * Creates an instance of User.
     * @param { UserObj } userObj
     * @memberof User
     * @returns { User }
     */
  static init(userObj) {
    return new User(userObj);
  }

}
