import $axios from 'axios';

let client = $axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'pt-br',
  },
});

export const doLogin = (credentials) => {
  return client
    .post(`/login`, credentials)
    .then(res => {
      return res.data;
    });
};

export const doForgot = (credentials) => {
  return client
    .post(`/forgot-password`, credentials)
    .then(res => {
      return res.data;
    });
};

export const doReset = (credentials) => {
  return client
    .post(`/reset-password`, credentials)
    .then(res => {
      return res.data;
    });
};

export const doLogout = (accessToken) => {
  return client
    .post(`/logout`, {access_token: accessToken})
    .then(res => {
      return res.data;
    });
};

export const getAuthenticatedUser = (accessToken) => {
  return client
    .get(`/partner/users/profile`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then(res => {
      return res.data;
    });
};
