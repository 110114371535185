import { doLogin, doForgot, doReset, getAuthenticatedUser } from '@/api/Auth.js';

import { User } from '@/models/User.js';

export const state = {
  currentUser: JSON.parse(localStorage.getItem('auth.currentUser')),
  accessToken: localStorage.getItem('auth.accessToken'),
  partner: JSON.parse(localStorage.getItem('auth.partner')),
};

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    saveState('auth.currentUser', JSON.parse(JSON.stringify(newValue)));
    state.currentUser = JSON.parse(JSON.stringify(newValue));
  },
  SET_PARTNER(state, newValue) {
    saveState('partner', JSON.parse(JSON.stringify(newValue)));
    state.partner = JSON.parse(JSON.stringify(newValue));
  },
  SET_ACCESS_TOKEN(state, newValue) {
    state.accessToken = newValue;
    saveState('auth.accessToken', newValue);
  },
  DEL_CURRENT_USER(state) {
    state.currentUser = null;
    deleteState('auth.currentUser');
  },
  DEL_ACCESS_TOKEN(state) {
    state.accessToken = null;
    deleteState('auth.accessToken');
  },
  DEL_PARTNER(state) {
    state.partner = null;
    deleteState('partner');
  },
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser;
  },
};

export const setters = {
  // Whether the user is currently logged in.
  setCurrentUser(state, value) {
    state.currentUser = value;
    saveState('auth.currentUser', value);
  },
};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch('validate');
  },

  // Logs in the current user.
  logIn({ commit, dispatch, getters }, { email, password } = {}) {
    if (getters.loggedIn) return dispatch('validate');

    return doLogin({email: email, password: password, source: 'partner'}).then((response) => {
      commit('SET_CURRENT_USER', response.user  );
      commit('SET_ACCESS_TOKEN', response.access_token);
      commit('SET_PARTNER', response.partner  );
    });
  },

  // Logs out the current user.
  logOut({ commit }) {
    commit('DEL_CURRENT_USER', null);
    commit('DEL_ACCESS_TOKEN', null);
    commit('DEL_PARTNER', null);
  },

  // register the user
  // eslint-disable-next-line no-unused-vars
  forgotPassword({ commit, dispatch, getters }, { email } = {}) {
    if (getters.loggedIn) return dispatch('validate');

    return doForgot({ email: email }).then((response) => {
      return response
    });
  },

  // register the user
  // eslint-disable-next-line no-unused-vars
  resetPassword({ commit, dispatch, getters }, { email, password, token } = {}) {
    if (getters.loggedIn) return dispatch('validate');

    return doReset({ email: email, password: password, token: token }).then((response) => {
      return response
    });
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  // eslint-disable-next-line no-unused-vars
  validate({ commit, state }) {
    if (!state.accessToken) return Promise.resolve(null);

    const user = getAuthenticatedUser(localStorage.getItem('auth.accessToken')).then((response) => {
      commit('SET_CURRENT_USER', new User(response));
      return response;
    }).catch(() => {
      commit('DEL_CURRENT_USER', null);
      commit('DEL_ACCESS_TOKEN', null);
      commit('DEL_PARTNER', null);

      location.reload();
    });

    return user;
  },
};

// ===
// Private helpers
// ===

function saveState(key, state) {
  if(typeof state === "string"){
    window.localStorage.setItem(key, state);
  }else{
    window.localStorage.setItem(key, JSON.stringify(state));
  }
}

function deleteState(key) {
  window.localStorage.removeItem(key);
}
