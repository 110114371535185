import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/pt_BR.json';
import VueMask from 'v-mask';
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import VueAWN from "vue-awesome-notifications";
import VueCurrencyInput from 'vue-currency-input';

import vco from "v-click-outside";
import router from './router/index';

import "@/design/index.scss";
import VueSweetalert2 from 'vue-sweetalert2';
import store from '@/state/store';

import App from './App.vue';
import i18n_messages from "@/i18n";

Vue.use(VueRouter);
Vue.use(vco);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VueMask);
Vue.use(require('vue-chartist'));
Vue.use(VueSweetalert2);
Vue.use(VueI18n);
Vue.use(VueCurrencyInput, {
  globalOptions: {
    locale: 'pt-BR',
    currency: null,
  },
  componentName: 'BInputMoney',
});
Vue.use(VueAWN, {
  // asyncBlockMinDuration: 500,
  maxNotifications: 5,
  position: "top-right",
  labels: {
    info: "Informação",
    success: "Sucesso",
    warning: "Atenção",
    alert: "Erro",
    async: "Aguarde",
    confirm: "Confirmação necessária"
  },
  modal: {
    okLabel: "Confirmar",
    cancelLabel: "Cancelar"
  },
  messages: {
    async: "Enviando requisição para o servidor..."
  },
  icons: {
    prefix: "<i style='font-size:42px' class='bs bs-",
    sufix: "'></i>",
    tip: "help-circle",
    info: "info-circle",
    success: "check-circle",
    warning: "info-circle",
    alert: "info-circle",
    async: "loader-alt spinner-border",
    confirm: "info-circle"
  }
})

Vue.component('apexchart', VueApexCharts);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule],
    message: messages[rule]
  });
});

extend('nif', val => {
  if (val.length == 11) {
    var cpf = val.trim();

    cpf = cpf.replace(/\./g, '');
    cpf = cpf.replace('-', '');
    cpf = cpf.split('');

    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cpf.length > i; i++) {
      if (cpf[i - 1] != cpf[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
      v1 += cpf[i] * p;
    }

    v1 = ((v1 * 10) % 11);

    if (v1 == 10) {
      v1 = 0;
    }

    if (v1 != cpf[9]) {
      return false;
    }

    for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
      v2 += cpf[i] * p;
    }

    v2 = ((v2 * 10) % 11);

    if (v2 == 10) {
      v2 = 0;
    }

    if (v2 != cpf[10]) {
      return false;
    } else {
      return true;
    }
  } else if (val.length == 14) {
    var cnpj = val.trim();

    cnpj = cnpj.replace(/\./g, '');
    cnpj = cnpj.replace('-', '');
    cnpj = cnpj.replace('/', '');
    cnpj = cnpj.split('');

    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cnpj.length > i; i++) {
      if (cnpj[i - 1] != cnpj[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v1 += cnpj[i] * p1;
      } else {
        v1 += cnpj[i] * p2;
      }
    }

    v1 = (v1 % 11);

    if (v1 < 2) {
      v1 = 0;
    } else {
      v1 = (11 - v1);
    }

    if (v1 != cnpj[12]) {
      return false;
    }

    for (var i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v2 += cnpj[i] * p1;
      } else {
        v2 += cnpj[i] * p2;
      }
    }

    v2 = (v2 % 11);

    if (v2 < 2) {
      v2 = 0;
    } else {
      v2 = (11 - v2);
    }

    if (v2 != cnpj[13]) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
});

const dateTimeFormats = {
  'en': {
    short: {
      year: 'numeric', month: 'numeric', day: 'numeric',
    },
    long: {
      year: 'numeric', month: 'numeric', day: 'numeric',
      weekday: 'numeric', hour: 'numeric', minute: 'numeric',
    },
  },
  'pt': {
    short: {
      year: 'numeric', month: 'numeric', day: 'numeric',
    },
    long: {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric',
    },
  },
  'es': {
    short: {
      year: 'numeric', month: 'numeric', day: 'numeric',
    },
    long: {
      year: 'numeric', month: 'numeric', day: 'numeric',
      weekday: 'numeric', hour: 'numeric', minute: 'numeric',
    },
  },
};

const numberFormats = {
  'usd': {
    currency: {
      style: 'currency', currency: 'USD', currencyDisplay: 'symbol',
    },
    percent: {
      style: 'percent',
    },
  },
  'brl': {
    currency: {
      style: 'currency', currency: 'BRL', currencyDisplay: 'symbol',
    },
    percent: {
      style: 'percent',
    },
  },
  'eur': {
    currency: {
      style: 'currency', currency: 'EUR', currencyDisplay: 'symbol',
    },
    percent: {
      style: 'percent',
    },
  },
};

const i18n = new VueI18n({ locale: 'pt', silentTranslationWarn: true, messages:i18n_messages, dateTimeFormats, numberFormats });

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app');
